import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { ReactNode, useId, useRef } from 'react';
import { Box } from '../../../layout/Box/Box.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { Stack } from '../../../layout/Stack/Stack.tsx';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Chevron } from '../../Chevron/Chevron.tsx';
import { Icons } from '../../Icons/components/icons.ts';
import { IconName } from '../../Icons/types.ts';
import { OptionalTooltip } from '../../Tooltip/OptionalTooltip.tsx';
import { RefinedNavigationItem } from './RefinedNavigationLink.tsx';

type Props = Readonly<{
  children: ReactNode;
  toggleDisabled?: boolean;
  iconName: IconName;
  label: string;
  isExpanded: boolean;
  onIsExpandedChange: (isExpanded: boolean) => void;
}>;

export const RefinedNavigationToggle = ({
  iconName,
  toggleDisabled,
  label,
  isExpanded,
  onIsExpandedChange,
  children,
}: Props) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  const controlledElementId = useId();
  const Icon = Icons[iconName];
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { buttonProps } = useButton(
    {
      isDisabled: toggleDisabled,
      elementType: 'div',
      onPress: () => onIsExpandedChange(!isExpanded),
      'aria-expanded': isExpanded,
      'aria-controls': controlledElementId,
    },
    buttonRef,
  );

  return (
    <Box component="li">
      <Stack spacing={Spacing.XS}>
        <OptionalTooltip
          placement="right"
          text={label}
          customRenderText={(truncatedElementRef) => (
            <RefinedNavigationItem
              $isFocusVisible={isFocusVisible}
              $isDisabled={toggleDisabled}
              {...mergeProps(focusProps, buttonProps)}
            >
              <Row alignY="center" spacingX={Spacing.S} noWrap>
                <Column width="content">
                  <Icon size={IconSize.S} />
                </Column>
                <Column>
                  {label && (
                    <Box display="flex">
                      <Box
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        ref={truncatedElementRef}
                      >
                        {label}
                      </Box>
                    </Box>
                  )}
                </Column>
                {!toggleDisabled && (
                  <Column width="content">
                    <Chevron direction={isExpanded ? 'up' : 'down'} />
                  </Column>
                )}
              </Row>
            </RefinedNavigationItem>
          )}
        />

        {isExpanded && (
          <Box paddingLeft={Spacing.XL}>
            <Stack component="ul" id={controlledElementId} align="start" spacing={Spacing.XS}>
              {children}
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
