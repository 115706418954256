import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import styled from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { borderWidthActiveLink } from '../../../tokens/decision/border.ts';
import { colorAccent } from '../../../tokens/decision/colors.ts';
import { RouterLink } from '../../Anchor/Anchor.tsx';
import { OptionalTooltip } from '../../Tooltip/OptionalTooltip.tsx';
import { RefinedNavigationItem } from './RefinedNavigationLink.tsx';

type Props = Readonly<{
  id?: string;
  isActive?: boolean;
  label: string;
  to: string;
}>;

const StyledLabelWrapper = styled(Box)<{ readonly $isActive?: boolean }>`
  border-bottom:${({ $isActive }) => ($isActive ? `${borderWidthActiveLink}px solid ${colorAccent}` : 'none')};
`;

export const RefinedNavigationSubLink = ({ id, isActive, label, to, ...props }: Props) => {
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Box component="li" maxWidth="100%">
      <OptionalTooltip
        placement="right"
        text={label}
        customRenderText={(truncatedElementRef) => (
          <RefinedNavigationItem
            as={RouterLink}
            id={id}
            to={to}
            $isActive={isActive}
            $isFocusVisible={isFocusVisible}
            $isSubLink
            {...mergeProps(focusProps, props)}
          >
            <StyledLabelWrapper
              $isActive={isActive}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              ref={truncatedElementRef}
            >
              {label}
            </StyledLabelWrapper>
          </RefinedNavigationItem>
        )}
      />
    </Box>
  );
};
