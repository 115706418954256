import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Stack } from '../../layout/Stack/Stack.tsx';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { px } from '../../tokens/utils/utils.ts';
import { RefinedNavigationIconPlaceholder } from './components/RefinedNavigationIconPlaceholder.tsx';
import { RefinedNavigationLink } from './components/RefinedNavigationLink.tsx';
import { RefinedNavigationSubLink } from './components/RefinedNavigationSubLink.tsx';
import { RefinedNavigationToggle } from './components/RefinedNavigationToggle.tsx';

type Props = {
  readonly children: ReactNode;
  readonly isVisuallyHidden?: boolean;
};

export const StyledWrapper = styled.div<{ readonly isVisuallyHidden?: boolean }>`
  width: 100%;
  padding: ${px(Spacing.L)} ${px(Spacing.S)};
  overflow-y: auto;
  
  ${({ isVisuallyHidden }) =>
    isVisuallyHidden &&
    css`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    border: 0;
  `}
`;

const RefinedNavigationComponent = ({ children, isVisuallyHidden }: Props) => {
  return (
    <StyledWrapper isVisuallyHidden={isVisuallyHidden}>
      <Stack component="ul" spacing={Spacing.L}>
        {children}
      </Stack>
    </StyledWrapper>
  );
};

const componentComposition = {
  Link: RefinedNavigationLink,
  Toggle: RefinedNavigationToggle,
  SubLink: RefinedNavigationSubLink,
  Icon: RefinedNavigationIconPlaceholder,
} as const;

export const RefinedNavigation = Object.assign(RefinedNavigationComponent, componentComposition);
