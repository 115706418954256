import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import styled, { css } from 'styled-components';
import { Box } from '../../../layout/Box/Box.tsx';
import { Column } from '../../../layout/Row/Column.tsx';
import { Row } from '../../../layout/Row/Row.tsx';
import { borderWidthActiveLink } from '../../../tokens/decision/border.ts';
import {
  colorAccent,
  colorBackgroundHover,
  colorTextDefault,
} from '../../../tokens/decision/colors.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { transitionBgHover } from '../../../tokens/decision/transitions.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { shadowFocusStyles } from '../../../tokens/quarks/shadow.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { RouterLink } from '../../Anchor/Anchor.tsx';
import { Icons } from '../../Icons/components/icons.ts';
import { IconName } from '../../Icons/types.ts';
import { OptionalTooltip } from '../../Tooltip/OptionalTooltip.tsx';

export const RefinedNavigationItem = styled.div<{
  readonly $isActive?: boolean;
  readonly $isDisabled?: boolean;
  readonly $isFocusVisible: boolean;
  readonly $isSubLink?: boolean;
}>`
  ${({ $isSubLink }) => ($isSubLink ? Typography.LabelLarge : Typography.LabelLargeEmphasis)};
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${px(Size.S)};
  padding: 0 ${px(Spacing.S)};
  
  color: ${colorTextDefault};
  border-radius: ${px(BorderRadius.S)};
  user-select: none;
  transition: background-color ${transitionBgHover};
  
  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
    cursor: default;
  `
      : css`
    &:hover {
      background-color:  ${colorBackgroundHover};
      color: ${colorTextDefault};
    }
  `};
      
  &:focus{
    color: ${colorTextDefault};
  }
 

  ${({ $isFocusVisible }) => $isFocusVisible && shadowFocusStyles};
`;

const StyledLabelWrapper = styled(Box)<{ readonly $isActive?: boolean }>`
  border-bottom:${({ $isActive }) => ($isActive ? `${borderWidthActiveLink}px solid ${colorAccent}` : 'none')};
`;

type Props = Readonly<{
  id?: string;
  isActive?: boolean;
  iconName: IconName;
  label: string;
  to: string;
}>;

export const RefinedNavigationLink = ({ id, isActive, label, iconName, to, ...props }: Props) => {
  const { isFocusVisible, focusProps } = useFocusRing();
  const Icon = iconName ? Icons[iconName] : undefined;

  return (
    <Box component="li">
      <OptionalTooltip
        placement="right"
        text={label}
        customRenderText={(truncatedElementRef) => (
          <RefinedNavigationItem
            as={RouterLink}
            id={id}
            $isFocusVisible={isFocusVisible}
            $isActive={isActive}
            to={to}
            {...mergeProps(focusProps, props)}
          >
            <Row alignX="start" alignY="center" spacingX={Spacing.S} noWrap>
              {Icon && (
                <Column width="content">
                  <Icon size={IconSize.S} />
                </Column>
              )}
              <Column>
                <StyledLabelWrapper
                  $isActive={isActive}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  ref={truncatedElementRef}
                >
                  {label}
                </StyledLabelWrapper>
              </Column>
            </Row>
          </RefinedNavigationItem>
        )}
      />
    </Box>
  );
};
