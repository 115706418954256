import { Box } from '../../../layout/Box/Box.tsx';
import { colorIconDefault } from '../../../tokens/decision/colors.ts';
import { Size } from '../../../tokens/decision/spacing.ts';
import { IconSize } from '../../../tokens/quarks/iconSize.ts';
import { Icons } from '../../Icons/components/icons.ts';
import { IconName } from '../../Icons/types.ts';

type IProps = {
  readonly iconName: IconName;
};

export const RefinedNavigationIconPlaceholder = ({ iconName }: IProps) => {
  const Icon = Icons[iconName];

  return (
    <Box height={Size.S} display="flex" alignItems="center" justifyContent="center">
      <Icon size={IconSize.S} color={colorIconDefault} />
    </Box>
  );
};
